<style lang="scss" scoped>
@import "./management.modal";

.modal-mask {
	position: fixed;
	z-index: 3;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity 0.3s ease;
}
.pay-icon {
  color: lightgreen;
  cursor: pointer;
}

.modal-content-container {
	background-color: white;
	width: 115vw;
	border-radius: 12px;
	padding-bottom: 5vh;
}

.user-management__header-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	border-radius: 10px 10px 0px 0px;
	background-color: var(--primary-color);
}

.user-management__header-title {
	color: #ffffff;
	font-weight: 500;
	font-size: 32px;
}

.closebtn-icon {
	color: #ffffff;
	font-size: 30px;
	cursor: pointer;
}

.edit-stores__new-store-container {
	display: flex;
	flex-direction: row-reverse;
	margin: 2em;
}

.management-table-container {
	margin: 0em 2em;
}

.page-table-header {
	background: #FFFEFC;
	border: 0.5px solid #E5E5E5;
	font-weight: 400;
	color: #605F5F;
	display: flex;
	padding: 1em 0em;
}

.page-table-header-text {
	padding-left: 10px;
	font-weight: 600;
	font-size: 1.19em;
	color: #505050;
}

.page-table-row {
	display: flex;
	align-items: center;
	font-weight: 300;
	font-size: 1.0em;
	color: #605F5F;
}

.page-table-desc-column {
	padding: 0.5em 0.7em;
}

.page-table-line-special {
	background: #F7F7F7;
}

.position-width {
	width: 5%;
}

.store-width {
	width: 27%;
}
.name-width{
	width: 20%;
}

.city-width {
	width: 12%;
}

.phone-width {
	width: 15%;
}

.cnpj-width {
	width: 14%;
}

.action-width {
	width: 6%;
}

.edit-icon {
	color: #505050;
	cursor: pointer;
}

.store-management__form-container {
	margin: 1em;
	max-height: 90vh;
	overflow-x: hidden;
	overflow-y: auto;
}

.stores-form__submit-wrapper {
	display: flex;
	flex-direction: row-reverse;
}

.store-management__store-title {
	color: #606060;
	font-weight: 400;
	font-size: 1.8em;
	line-height: 28px;
}

.input-title {
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #6c6b6b;
}

.page-table-line-special{background: #F7F7F7;}
.input-container {
	border: 1px solid #E5E5E5;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 12px;
	margin-top: 5px;
	display: flex;
}

.input-value {
	border: none;
	flex: 1;
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.15px;
	color: #696969;
}

.user-management__body {
	height: 95vh;
	overflow: auto;
}

.input-value:focus {
	border: none;
	outline: none;
}

.page-table-header-mobile {
	display: none;
}
.bill-width{
	width: 10%;
}
.bill-alert{
	color: lightcoral;
}

@media only screen and (max-width: 650px) {
	.page-table-header {
		display: none;
	}

	.page-table-row {
		flex-direction: column;
		font-size: 3.5vw;
	}

	.position-width {
		width: 100%;
	}

	.store-width {
		width: 100%;
	}

	.name-width{
		width: 100%;
	}

	.city-width {
		width: 100%;
	}

	.phone-width {
		width: 100%;
	}

	.cnpj-width {
		width: 100%;
	}

	.action-width {
		width: 100%;
	}

	.page-table-desc-column {
		display: flex;
		justify-content: space-between;
	}

	.page-table-header-mobile {
		font-size: 1.2em;
		display: flex;
		font-weight: bold;
	}
}
</style>
<template>
	<div class="modal-mask">
		<div class="modal-content-container">
			<div class="user-management__header-container">
				<span class="user-management__header-title">
					Editar Empresas
				</span>
				<span @click="closeDialog" class="material-icons closebtn-icon">
					cancel
				</span>
			</div>
			<div class="user-management__body">
				<div v-if="!showEditStoreForm" class="edit-stores__new-store-container">
					<Button :iconName="'add_circle'" title="Adicionar Novo" :text="'Adicionar Novo'" :action="add_new" />
				</div>
				<div v-if="!showEditStoreForm" class="management-table-container">
					<div class="page-table-content">
						<div class="page-table-header">
							<div class="page-table-header-text position-width">#</div>
							<div class="page-table-header-text store-width">Empresa</div>
							<div class="page-table-header-text name-width">Nome abreviado</div>
							<div class="page-table-header-text city-width">Cidade</div>
							<div class="page-table-header-text phone-width">Telefone</div>
							<div class="page-table-header-text cnpj-width">CNPJ</div>
							<div class="page-table-header-text bill-width">Boletos</div>
							<div class="page-table-header-text action-width">Ação</div>
						</div>
						<div v-show="store.visible" v-for="(store, idx) in stores" :key="store.loj_id"
							:class="{ active: current_store && current_store.id == store.id, 'page-table-line-special' : idx % 2 != 0 }">
							<div class="page-table-row">
								<div class="page-table-desc-column position-width">
									<div class="page-table-header-mobile">#</div>
									{{ store.position }}
								</div>
								<div class="page-table-desc-column store-width">
									<div class="page-table-header-mobile">Empresa</div>
									{{ store.social_reazon }} <br>
                            		{{ store.trading_name }}
								</div>
								<div class="page-table-desc-column name-width" >
									<div class="page-table-header-mobile">Nome abreviado</div>
									{{ store.name }}
								</div>
								<div class="page-table-desc-column city-width">
									<div class="page-table-header-mobile">Cidade</div>
									{{ store.city ? store.city.name : '-' }}
								</div>
								<div class="page-table-desc-column phone-width">
									<div class="page-table-header-mobile">Telefone</div>
									<cc-whatsapp :phone="store.phone" />
								</div>
								<div class="page-table-desc-column cnpj-width">
									<div class="page-table-header-mobile">CNPJ</div>
									{{ `${parse_cnpj(store.cnpj)}` || "" | VMask('## ### ###/####-##') }}
								</div>
								<div class="page-table-desc-column bill-width" :class="{'bill-alert': store.conexa_boleto_qtd > 0}">
									<div class="page-table-header-mobile">Boletos</div>
									{{ store.conexa_boleto_qtd }}
								</div>
								<div class="page-table-desc-column action-width">
									<div class="page-table-header-mobile">
										Ação
									</div>
									<div>
										<span title="Editar empresa" class="material-icons edit-icon"
											@click="toggle_user(store)"> edit </span>
										<span @click="deleteStore(store)" title="Deletar empresa"
											class="material-icons-outlined"
											style="color: lightcoral; cursor: pointer;">delete</span>
										<span v-if="store.conexa_boleto_qtd > 0" @click="payModalUser = store" class="material-icons-outlined pay-icon">paid</span>
										
									</div>

								</div>
							</div>
						</div>
						<PayModal v-if="payModalUser" :store="payModalUser" :closeDialog="()=>payModalUser = false"/>
					</div>
					<!-- <div v-if="!showEditStoreForm" class=".management-table-container">
							<div class="page-table-content">
							<div class="page-table-header">
								<div class="page-table-header-text position-width">#</div>
								<div class="page-table-header-text store-width">Empresa</div>
								<div class="page-table-header-text city-width">Cidade</div>
								<div class="page-table-header-text phone-width">Telefone</div>
								<div class="page-table-header-text cnpj-width">CNPJ</div>
								<div class="page-table-header-text action-width">Ação</div>
							</div>
							<div v-show="store.visible" 
								v-for="store in stores" 
								:key="store.loj_id"
								:class="{ active: current_store && current_store.id == store.id }">
								<div class="page-table-row">
								<div class="page-table-desc-column position-width">{{ store.position }}</div>
								<div class="page-table-desc-column store-width">{{ store.name }}</div>
								<div class="page-table-desc-column city-width">{{ store.city ? store.city.name : '-' }}</div>
								<div class="page-table-desc-column phone-width">
									<cc-whatsapp :phone="store.phone" />
								</div>
								<div class="page-table-desc-column cnpj-width">
									{{ `${parse_cnpj(store.cnpj)}` || "" | VMask('## ### ###/####-##') }}
								</div>
								<div class="page-table-desc-column action-width">
									<span class="material-icons edit-icon" @click="toggle_user(store)" >
									edit
									</span>
								</div>
								</div>
							</div>
							</div>
						</div> -->

				</div>
				<div class="store-management__form-container" v-if="current_store && showEditStoreForm">
					<div>
						<span class="store-management__store-title">#{{ current_store ? current_store.name : '' }}</span>
						<ValidationObserver v-slot="{ handleSubmit }">
							<form action="" @submit.prevent="handleSubmit(save)">
								<div class="row pt-2">
									<div v-if="message" class="col-md-12 col-lg-12">
										<h4 class="py-4 text-center text-danger">{{ message }}</h4>
									</div>
								</div>
								<div class="row py-1 d-flex justify-content-center">
									<div class="col-md-3 col-lg-6">
										<!-- <label>CNPJ:<span style="color:red !important">*</span></label> -->
										<div class="input-title" title="Obrigatório">
											CNPJ
											<span class="material-icons" style="color: #CB595B;">
												info
											</span>
										</div>
										<ValidationProvider rules="required" v-slot="v">
											<!-- <cc-input v-mask="'## ### ###/####-##'" type="text" v-model="current_store.cnpj" :readonly="current_store.id"></cc-input> -->
										<div class="input-container">
											<input type="text" v-mask="'## ### ###/####-##'"
												v-on:keyup.enter="cnpjEnter()" v-model="current_store.cnpj"
												class="input-value" placeholder="Digite o CNPJ">
											</div>
											<span class="error">{{ v.errors[0] }}</span>
										</ValidationProvider>
									</div>
									<div class="col-md-3 col-lg-6">
										<!-- <label>Nome:<span style="color:red !important">*</span></label> -->
										<div class="input-title" title="Obrigatório">
											Nome
											<span class="material-icons" style="color: #CB595B;">
												info
											</span>
										</div>
										<ValidationProvider rules="required" v-slot="v">
											<!-- <cc-input type="text" v-model="current_store.name"></cc-input> -->
											<div class="input-container">
												<input type="text" v-model="current_store.name" class="input-value"
												placeholder="Digite o nome">
										</div>
										<span class="error">{{ v.errors[0] }}</span>
									</ValidationProvider>
								</div>

							</div>
							<div class="row py-1  d-flex justify-content-center">
								<div class="col-md-3 col-lg-6">
									<!-- <label>Nome Fantasia:<span style="color:red !important">*</span></label> -->
									<div class="input-title" title="Obrigatório">
										Nome Fantasia
										<span class="material-icons" style="color: #CB595B;">
											info
										</span>
									</div>
									<ValidationProvider rules="required" v-slot="v">
										<!-- <cc-input type="text" v-model="current_store.trading_name"></cc-input> -->
										<div class="input-container">
											<input type="text" v-model="current_store.trading_name" class="input-value"
												placeholder="Digite o nome fantasia">
										</div>
										<span class="error">{{ v.errors[0] }}</span>
									</ValidationProvider>
								</div>
								<div class="col-md-3 col-lg-6">
									<!-- <label>Razão Social:<span style="color:red !important">*</span></label> -->
									<div class="input-title" title="Obrigatório">
										Razão Social
										<span class="material-icons" style="color: #CB595B;">
											info
										</span>
									</div>
									<ValidationProvider rules="required" v-slot="v">
										<!-- <cc-input type="text" v-model="current_store.social_reazon"></cc-input> -->
										<div class="input-container">
											<input type="text" v-model="current_store.social_reazon" class="input-value"
												placeholder="Digite a razão social">
										</div>
										<span class="error">{{ v.errors[0] }}</span>
									</ValidationProvider>
								</div>
							</div>
							<div class="row py-1 d-flex justify-content-center">
								<div class="col-md-3 col-lg-6">
									<!-- <label>Email:<span style="color:red !important">*</span></label> -->
									<div class="input-title" title="Obrigatório">
										Email
										<span class="material-icons" style="color: #CB595B;">
											info
										</span>
									</div>
									<ValidationProvider rules="required" v-slot="v">
										<!-- <cc-input type="text" v-model="current_store.email"></cc-input> -->
										<div class="input-container">
											<input type="text" v-model="current_store.email" class="input-value"
												placeholder="Digite o email">
										</div>
										<span class="error">{{ v.errors[0] }}</span>
									</ValidationProvider>
								</div>
								<div class="col-md-3 col-lg-6">
									<!-- <label>Telefone:<span style="color:red !important">*</span></label> -->
									<div class="input-title" title="Obrigatório">
										Telefone
										<span class="material-icons" style="color: #CB595B;">
											info
										</span>
									</div>
									<ValidationProvider rules="required" v-slot="v">
										<!-- <cc-input v-mask="'(##) #.####-####'" type="text" v-model="current_store.phone"></cc-input> -->
										<div class="input-container">
											<input type="text" v-mask="'(##) #.####-####'" v-model="current_store.phone"
												class="input-value" placeholder="Digite o telefone">
										</div>
										<span class="error">{{ v.errors[0] }}</span>
									</ValidationProvider>
								</div>
							</div>
							<div class="row py-1 d-flex justify-content-center">
								<div class="col-md-3 col-lg-6">
									<!-- <label>CEP:<span style="color:red !important">*</span></label> -->
									<div class="input-title" title="Obrigatório">
										CEP
										<span class="material-icons" style="color: #CB595B;">
											info
										</span>
									</div>
									<ValidationProvider rules="required" v-slot="v">
										<!-- <cc-input v-mask="'##.###-###'" type="text" v-model="current_store.zip_code"></cc-input> -->
										<div class="input-container">
											<input type="text" v-mask="'##.###-###'" v-model="current_store.zip_code"
												class="input-value" placeholder="Digite o CEP">
										</div>
										<span class="error">{{ v.errors[0] }}</span>
									</ValidationProvider>
								</div>
								<div class="col-md-3 col-lg-6">
									<!-- <label>CEP:<span style="color:red !important">*</span></label> -->
									<div class="input-title" title="Obrigatório">
										Numero loja
										<span class="material-icons" style="color: #CB595B;">
											info
										</span>
									</div>
									<ValidationProvider rules="required" v-slot="v">
										<!-- <cc-input v-mask="'##.###-###'" type="text" v-model="current_store.zip_code"></cc-input> -->
										<div class="input-container">
											<input type="number" v-model="current_store.position" class="input-value"
												placeholder="Digite a posição">
										</div>
										<span class="error">{{ v.errors[0] }}</span>
									</ValidationProvider>
								</div>
							</div>
							<div class="row py-1 d-flex justify-content-center">

								<div class="col-md-3 col-lg-6">
									<!-- <label>Estado:<span style="color:red !important">*</span></label> -->
									<div class="input-title" title="Obrigatório" style="margin-bottom: 5px;">
										Estado
										<span class="material-icons" style="color: #CB595B;">
											info
										</span>
									</div>
									<ValidationProvider rules="required" v-slot="v">
										<cc-select-v2 :tracker="'id'" :text="'name'" :searchable="true"
											v-model="current_store.state" :options="states" @select="set_state" />
										<span class="error">{{ v.errors[0] }}</span>
									</ValidationProvider>
								</div>
								<div class="col-md-3 col-lg-6">
									<!-- <label>Cidade:<span style="color:red !important">*</span></label> -->
									<div class="input-title" title="Obrigatório" style="margin-bottom: 5px;">
										Cidade
										<span class="material-icons" style="color: #CB595B;">
											info
										</span>
									</div>
									<ValidationProvider rules="required" v-slot="v">
										<cc-select-v2 :tracker="'id'" :text="'name'" :searchable="true"
											v-model="current_store.city" :options="available_cities"
											@select="set_city" />
										<span class="error">{{ v.errors[0] }}</span>
									</ValidationProvider>
								</div>
							</div>
							<div class="row py-1 d-flex justify-content-center">
								<div class="col-md-3 col-lg-8">
									<!-- <label>Rua:<span style="color:red !important">*</span></label> -->
									<div class="input-title" title="Obrigatório" style="margin-bottom: 5px;">
										Rua
										<span class="material-icons" style="color: #CB595B;">
											info
										</span>
									</div>
									<!-- <cc-input type="text" v-model="current_store.street"></cc-input> -->
									<div class="input-container">
										<input type="text" v-model="current_store.street" class="input-value"
											placeholder="Digite o endereço">
									</div>
								</div>
								<div class="col-md-3 col-lg-4">
									<!-- <label>Bairro:<span style="color:red !important">*</span></label> -->
									<div class="input-title" title="Obrigatório" style="margin-bottom: 5px;">
											Bairro
											<span class="material-icons" style="color: #CB595B;">
												info
											</span>
										</div>
										<ValidationProvider rules="required" v-slot="v">
											<!-- <cc-input type="text" v-model="current_store.district"></cc-input> -->
											<div class="input-container">
												<input type="text" v-model="current_store.district" class="input-value"
													placeholder="Digite o bairro">
											</div>
											<span class="error">{{ v.errors[0] }}</span>
										</ValidationProvider>
									</div>
								</div>
								<div class="row py-1 d-flex justify-content-center">
									<div class="col-md-3 col-lg-12">
										<!-- <label>Complemento:</label> -->
										<div class="input-title" title="Obrigatório" style="margin-bottom: 5px;">
											Complemento
										</div>
										<!-- <cc-input type="text" v-model="current_store.complement"></cc-input> -->
										<div class="input-container">
											<input type="text" v-model="current_store.complement" class="input-value"
												placeholder="Digite o bairro">
										</div>
									</div>
								</div>
								<div class="row pt-2">
									<div v-if="message" class="col-md-12 col-lg-12">
										<h4 class="py-4 text-center text-danger">{{ message }}</h4>
									</div>
								</div>
								<!-- <div class="col d-flex justify-content-end">
									<button id="save-btn" class="btnFeedbackModal" type="submit">
									Salvar
									</button>
								</div> -->
								<div class="stores-form__submit-wrapper">
									<Button style="margin-left: 10px;" title="Salvar" :text="'Salvar'"
										:action="() => { handleSubmit(save) }" />
									<Button style="margin-left: 10px;" :iconName="'arrow_back'" title="Voltar"
										:text="'Voltar'" :action="closeEditStoreForm" />
								</div>
							</form>
						</ValidationObserver>
					</div>
				</div>
			</div>

		</div>
	</div>
	<!-- <cc-modal :modal="modal" @close="close">
			        <div class="col col-2 mb-3" slot="header-actions">
			            <cc-button @click="add_new" :content="'Adicionar Novo'" :icon="'fa fa-plus'" :classes="'fixed success'" />
			        </div>
					<div slot="body" class="container-fluid pt-0 sellers">
						<div class="row contents shadow p-4 d-flex justify-content-center">
							<div class="col" id="sellers">
								<div class="list-group">
									<table class="table">
										<thead>
											<tr>
												<th class="text-left px-3">#</th>
												<th class="text-left px-3">Empresa</th>
												<th class="text-left px-3" v-show="!current_store">Cidade</th>
												<th class="text-left px-3" v-show="!current_store">Telefone</th>
												<th class="text-left px-0" v-show="!current_store">CNPJ</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<tr v-show="store.visible" v-for="store in stores" :key="store.loj_id" @click="toggle_user(store)" class="pointer" :class="{ active: current_store && current_store.id == store.id }">
												<td class="text-left px-3"><b>{{ store.position }}</b></td>
												<td class="text-left px-3 text-uppercase"><b>{{ store.name }}</b></td>
												<td class="text-left px-3" v-show="!current_store"><b>{{ store.city ? store.city.name : '-' }}</b></td>
												<td class="text-left px-3" v-show="!current_store">
			                                        <cc-whatsapp :phone="store.phone" />
			                                    </td>
												<td class="text-left px-0" v-show="!current_store">{{ `${parse_cnpj(store.cnpj)}` || "" | VMask('## ### ###/####-##') }}</td>
												<td>
													<cc-button :icon="'fa fa-pencil'" :content="'Editar'" :classes="'fixed'" class="show-products"/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div id="seller-products" class="col" v-if="current_store">
								<label for="" class="label-control"><b>#{{ current_store ? current_store.name : ''}}</b></label>
								<div class="list-group p-4">
									<ValidationObserver v-slot="{ handleSubmit }">
										<form action="" @submit.prevent="handleSubmit(save)">
											<div class="row pt-2">
			                                    <div v-if="message" class="col-md-12 col-lg-12">
			                                        <h4 class="py-4 text-center text-danger">{{message}}</h4>
			                                    </div>
			                                    <div class="col d-flex justify-content-end">
			                                        <button id="save-btn" class="btnFeedbackModal" type="submit">
														Salvar
													</button>
			                                    </div>
			                                </div>
											<div class="row py-1 d-flex justify-content-center">
												<div class="col-md-3 col-lg-6">
													<label>Nome:<span style="color:red !important">*</span></label>
													<ValidationProvider rules="required" v-slot="v">
														<cc-input type="text" v-model="current_store.name"></cc-input>
														<span class="error">{{ v.errors[0] }}</span>
			                                        </ValidationProvider>
												</div>
												<div class="col-md-3 col-lg-6">
													<label>CNPJ:<span style="color:red !important">*</span></label>
													<ValidationProvider rules="required" v-slot="v">
														<cc-input v-mask="'## ### ###/####-##'" type="text" v-model="current_store.cnpj" :readonly="current_store.id"></cc-input>
														<span class="error">{{ v.errors[0] }}</span>
			                                        </ValidationProvider>
												</div>
											</div>

											<div class="row py-1  d-flex justify-content-center">
												<div class="col-md-3 col-lg-6">
													<label>Nome Fantasia:<span style="color:red !important">*</span></label>
													<ValidationProvider rules="required" v-slot="v">
														<cc-input type="text" v-model="current_store.trading_name"></cc-input>
														<span class="error">{{ v.errors[0] }}</span>
			                                        </ValidationProvider>
												</div>
												<div class="col-md-3 col-lg-6">
													<label>Razão Social:<span style="color:red !important">*</span></label>
													<ValidationProvider rules="required" v-slot="v">
														<cc-input type="text" v-model="current_store.social_reazon"></cc-input>
														<span class="error">{{ v.errors[0] }}</span>
			                                        </ValidationProvider>
												</div>
											</div>

											<div class="row py-1 d-flex justify-content-center">
												<div class="col-md-3 col-lg-6">
													<label>Email:<span style="color:red !important">*</span></label>
													<ValidationProvider rules="required" v-slot="v">
														<cc-input type="text" v-model="current_store.email"></cc-input>
														<span class="error">{{ v.errors[0] }}</span>
			                                        </ValidationProvider>
												</div>
												<div class="col-md-3 col-lg-6">
													<label>Telefone:<span style="color:red !important">*</span></label>
													<ValidationProvider rules="required" v-slot="v">
														<cc-input v-mask="'(##) #.####-####'" type="text" v-model="current_store.phone"></cc-input>
														<span class="error">{{ v.errors[0] }}</span>
			                                        </ValidationProvider>
												</div>
											</div>

											<div class="row py-1 d-flex justify-content-center">
												<div class="col-md-3 col-lg-4">
													<label>CEP:<span style="color:red !important">*</span></label>
													<ValidationProvider rules="required" v-slot="v">
														<cc-input v-mask="'##.###-###'" type="text" v-model="current_store.zip_code"></cc-input>
														<span class="error">{{ v.errors[0] }}</span>
			                                        </ValidationProvider>
												</div>
												<div class="col-md-3 col-lg-4">
													<label>Estado:<span style="color:red !important">*</span></label>
													<ValidationProvider rules="required" v-slot="v">
														<cc-select-v2 :tracker="'id'"
			                                                :text="'name'"
			                                                :searchable="true"
			                                                v-model="current_store.state"
			                                                :options="states" @select="set_state" />
														<span class="error">{{ v.errors[0] }}</span>
			                                        </ValidationProvider>
												</div>
												<div class="col-md-3 col-lg-4">
													<label>Cidade:<span style="color:red !important">*</span></label>
													<ValidationProvider rules="required" v-slot="v">
														<cc-select-v2
			                                                :tracker="'id'"
			                                                :text="'name'"
			                                                :searchable="true"
			                                                v-model="current_store.city"
			                                                :options="available_cities" @select="set_city" />
														<span class="error">{{ v.errors[0] }}</span>
			                                        </ValidationProvider>
												</div>
											</div>

											<div class="row py-1 d-flex justify-content-center">
												<div class="col-md-3 col-lg-8">
													<label>Rua:<span style="color:red !important">*</span></label>
													<cc-input type="text" v-model="current_store.street"></cc-input>
												</div>
												<div class="col-md-3 col-lg-4">
													<label>Bairro:<span style="color:red !important">*</span></label>
													<ValidationProvider rules="required" v-slot="v">
														<cc-input type="text" v-model="current_store.district"></cc-input>
														<span class="error">{{ v.errors[0] }}</span>
			                                        </ValidationProvider>
												</div>
											</div>
											<div class="row py-1 d-flex justify-content-center">
												<div class="col-md-3 col-lg-12">
													<label>Complemento:</label>
													<cc-input type="text" v-model="current_store.complement"></cc-input>
												</div>
											</div>
											<div class="row pt-2">
												<div v-if="message" class="col-md-12 col-lg-12">
													<h4 class="py-4 text-center text-danger">{{message}}</h4>
												</div>
											</div>
										</form>
									</ValidationObserver>
								</div>
							</div>
						</div>
					</div>
				</cc-modal> -->
</template>

<script>

import StoreService from "@/services/v3/clients/store.service";
import UserService from "@/services/v1/user.service";
import ErrorHandlerService from '@/services/ErrorHandlerService';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { gsap } from "gsap";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ClientService from "@/services/v3/client.service";
import Button from '@/components/ui/buttons/StandardButton.vue'
import BrasilApiService from "../../../services/BrasilApi";
import PayModal from "./PayModal.vue";

export default {
	mixins: [loaderMixin],
	components: {
		ValidationProvider,
		ValidationObserver,
		Button,
		PayModal
	},
	props: {
		client: {
			type: Object,
			required: true
		},
		closeDialog: {
			type: Function,
			required: true,
		}
	},
	data() {
		return {
			showEditStoreForm: false,
			stores: [],
			current_store: null,
			cities: [],
			states: [],
			loading: false,
			payModalUser: false,
			message: null,
			store_svc: new StoreService(),
			svc: new ClientService(),
			user_svc: new UserService(),
			brasilApi: new BrasilApiService(),
			modal: {
				title: `empresas`,
				subtitle: `Gerencie suas empresas`,
				icon_title: 'fas fa-store',
				cancel_text: 'Voltar',
				style: {
					width: "90%",
					height: "fit-content"
				}
			}
		};
	},
	computed: {
		available_cities: function (val) {
			return this.current_store && this.current_store.state ? this.cities.filter(c => c.est_id == this.current_store.state.id) : []
		}
	},
	methods: {
		cnpjEnter() {
			let cnpj = this.current_store.cnpj.replace('/', '')
			cnpj = cnpj.replace('-', '')
			cnpj = cnpj.replace(' ', '')
			cnpj = cnpj.replace(' ', '')
			this.brasilApi.getCnpjInfo(cnpj).then(resp => {
				this.$set(this.current_store, 'name', resp.data.razao_social)
				this.$set(this.current_store, 'trading_name', resp.data.razao_social.replace('LTDA', ''))
				this.$set(this.current_store, 'group_name', resp.data.razao_social.replace('LTDA', ''))
				this.$set(this.current_store, 'contact', resp.data.qsa.length > 0? resp.data.qsa[0].nome_socio : '')
				this.$set(this.current_store, 'phone', resp.data.ddd_telefone_1)
				this.$set(this.current_store, 'zip_code', resp.data.cep)
				this.$set(this.current_store, 'district', resp.data.bairro)
				this.$set(this.current_store, 'street', resp.data.logradouro + ' ' + resp.data.numero)
				this.states.forEach(element => {
				if( element.fu == resp.data.uf){
					this.$set(this.current_store, 'state', {...element})
					this.cities.forEach(element2 => {
						if( element2.est_id == element.id && element2.name.toLowerCase() == resp.data.municipio.toLowerCase()){
							this.$set(this.current_store, 'city', {...element2})
						}
					});
					this.$forceUpdate()
				}
				});
			})
		},
		deleteStore(store) {
			this.$swal.fire({
				title: "Confirma exclução da empresa?",
				text: "A empresa sera excluida do sistema.",
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Sim, excluir empresa!"
			}).then(response => {
				if (response.isConfirmed) {
					this.present_loader("excluindo empresa...")
					this.store_svc.delete(store.id).then(() => {
						this.load_stores()
					})
				}
			})
		},
		closeEditStoreForm() {
			this.showEditStoreForm = false
		},
		add_new() {
			this.toggle_user({ state: {}, city: {} })
			this.showEditStoreForm = true
		},
		parse_cnpj: function (val) {
			return val && `${val}`.length < 14 ? `0${val}` : val
		},
		set_state() {
			this.$set(this.current_store, 'state', this.current_store.state)
			this.$forceUpdate()
		},
		set_city() {
			this.$set(this.current_store, 'city', this.current_store.city)
			this.$forceUpdate()
		},
		toggle_user(store) {
			//let products_width = 70
			this.showEditStoreForm = true
			if (this.current_store && this.current_store.id == store.id) {
				/* gsap.to('#seller-products', { duration: 1, width: 0, onComplete: () => { this.current_store = null } })
				gsap.to('#sellers', { duration: 1.2, 'max-width': "100%", 'flex': "0 0 100%"}) */
			} else {
				this.current_store = store;
				/* gsap.fromTo('#seller-products', { 'width': "0%", 'flex': `0 0 0%` }, { duration: 1, 'flex': `0 0 ${products_width-6}%`, ease: 'expo.out'})
				gsap.fromTo('#sellers', { 'max-width': "100%", 'flex': '0 0 100%' }, { duration: 1, 'max-width': `"${100-products_width}%`, 'flex': `0 0 ${100-products_width}%`, ease: 'expo.out'}) */
			}
			this.$forceUpdate()
		},
		save() {
			/* this.present_loader("Salvando loja...") */
			
			let callback = () => {
				this.$swal.fire(
					'Sucesso!',
					'Modificações salvas com sucesso.',
					'success'
				);
				this.showEditStoreForm = false;
				setTimeout(() => {
					this.dismiss_loader()
					this.load_stores()
				}, 2000);
			}
			let callback_error = err => {
				if(err.response.data.errors.loj_cnpj){
					this.$swal.fire(
						err.response.data.errors.loj_cnpj[0],
						"Não foi possível cadastrar a loja.",
						"warning"
					)
				}
			}
			if (this.current_store.id) {
				this.store_svc.update(this.client, this.current_store).then(callback, callback_error)
			} else {
				this.store_svc.create(this.client, this.current_store).then(callback, callback_error)
			}
		},
		/* close() {
			if(this.current_store) {
				this.toggle_user(this.current_store)
			} else {
				this.$emit("close");
			}
		}, */
		load_stores() {
			return this.svc.load_stores(this.client.id)
				.then(response => response.data)
				.then(data => {
					this.stores = data;
					this.stores.forEach(s => s.visible = true)
					this.stores.forEach(s => {
						s.city = this.cities.find(ct => s.city && ct.id == s.city.id)
						s.state = this.states.find(st => s.state && st.id == s.state.id)
					})
					this.dismiss_loader()
				}).catch(error => {
					this.dismiss_loader()
					ErrorHandlerService.handle(error, this.$store);
				});
		},
		load_region_data() {
			this.present_loader("Carregando empresas...")
			return this.user_svc.region_data().then(response => response.data).then(data => {
				this.cities = data.cidades.map(c => ({ id: c.cid_id, name: c.cid_nome, est_id: c.est_id }))
				this.states = data.estados.map(c => ({ id: c.est_id, name: c.est_nome, fu: c.est_sigla }))
			})
		}
	},
	async mounted() {
		await this.load_region_data()
		this.load_stores()
	}
};
</script>
