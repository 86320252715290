import http from "../http";
import Service from "../../Service";

/**
 * @type StoreClient
 */
export default class StoreClient extends Service {

    update(client, store) {
		return http.put(`/admin/clients/${client.id}/stores/${store.id}`, store, {
			headers: this.getHeaders()
		});
	}

    create(client, store) {
		return http.post(`/admin/clients/${client.id}/stores`, store, {
			headers: this.getHeaders()
		});
	}

	delete(storeId){
		return http.delete(`/admin/clients/${storeId}/store`, {
			headers: this.getHeaders()
		})
	}

}
